import React from 'react';
import { graphql } from 'gatsby';
import SliceZoneSwitch from 'utils/sliceZoneSwitch';
import SEO from "utils/seo";

export const query = graphql`
	{
		prismic {
			allHomepages {
				edges {
					node {
						seo_title
						seo_description
						body {
							... on PRISMIC_HomepageBodyHero {
								type
								label
								primary {
									heading
									subheading
									description
									cta_label
									cta_action
									image
									imageSharp {
										childImageSharp {
											original {
												width
												height
												src
											}
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
							... on PRISMIC_HomepageBodyList_icon_info {
								type
								label
								primary {
									heading
									subheading
									description
								}
								fields {
									heading
									subheading
									image
									description
									cta_label
									cta_action {
										... on PRISMIC_Page {
											_meta {
												uid
											}
										}
									}
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
							... on PRISMIC_HomepageBodySplit_text_image {
								type
								label
								primary {
									heading
									subheading
									description
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
									cta_label
									cta_action
								}
							}
							... on PRISMIC_HomepageBodyForm {
								type
								label
								primary {
									form_name
									heading
									subheading
									description
									action {
										... on PRISMIC_Page {
											_meta {
												uid
											}
										}
									}
								}
								fields {
									required
									field_type
									field_name
								}
							}
							... on PRISMIC_HomepageBodyTestimonial {
								type
								label
								primary {
									testimonial
									author
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const IndexPage = (props) => {
	if (!props.data) {
		return null;
	}
	return (
		<>
			<SEO title={props.data.prismic.allHomepages.edges[0].node.seo_title} description={props.data.prismic.allHomepages.edges[0].node.seo_description}/>
			<SliceZoneSwitch body={props.data.prismic.allHomepages.edges[0].node.body} />
		</>
		)
};

export default IndexPage;
